#Overview {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 5rem;
    padding-top: 9rem;
    z-index: 0;

    gap: 11rem;

    align-items: center;
    justify-content: center;
}

.profile {
    display: flex;
    width: 850px;
    flex-direction: row;

    align-items: end;
    justify-content: space-between;
}

.me {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
}

.avatar {
    width: 8rem;
    border-radius: 50%;

    border: 1px solid rgb(var(--accent-color));
    animation: apear-opacity 0.5s ease-in-out,
        apear-scale 0.2s ease-in-out;
}

.name-container {
    position: relative;
    width: fit-content;
    overflow: visible;
    width: 19ch;
    font-size: 4rem;
}

.name {
    display: block;
    font-family: monospace;
    box-sizing: content-box;
    width: 19ch;
    margin: 0;

    color: rgb(var(--primary-color));

    text-wrap: nowrap;
    overflow: hidden;

    font-size: 4rem;
    text-align: start;

    animation: typing 4s steps(19);
}

.profesion {
    margin: 0;

    text-shadow: 2px 2px 3px rgba(var(--secondary-color), 0.3);
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 2px;
    color: #fbffc7;

    text-align: center;

    animation:
        apear-opacity 0.5s ease-in-out 4s,
        waiting 4s;
}

.contacto {
    align-items: center;
    justify-content: center;
}

.contacto ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 25px;
    list-style: none;
}

.contact-item {
    margin: 0;
}

.contact-item a {
    text-decoration: none;
    box-sizing: content-box;
    animation: apear-opacity 0.5s ease-in-out;

}

.contact-item a img {
    width: 45px;
    transition: 0.2s ease-in-out transform;
}

.contact-item a img:hover {
    transform: scale(1.1);
}

.skills {
    display: flex;
    margin: 0;
    gap: 6rem;

    animation: apear-opacity 0.5s ease-in-out;

    align-items: center;
    justify-content: center;
}

.presentacion {
    display: flex;
    flex-direction: column;
    max-width: 900px;

    padding: 5px;
    padding-inline: 15px;

    border-left: 2px solid rgba(var(--secondary-color), 0.3);

    justify-content: center;

    animation: apear-opacity 0.2s ease-in-out;
}

.presentacion p {
    margin-block: 10px !important;


    color: rgb(var(--primary-color));
    letter-spacing: 1px;
    font-size: large;
    text-align: start;
    text-wrap: balance;
}


@media (width <= 930px) {
    #Overview {
        padding-top: 3rem;
    }

    .profile {
        width: auto;
        align-items: end;
    }
    .me {
        align-items: center;
    }

    .name-container {
        width: auto;
    }


    .name {
        animation: none;
        text-wrap: balance;
        width: auto;

        font-size: 2.8rem;
        text-align: center;
    }

    .profesion {
        animation: none;
        font-size: 1.5rem;
        text-wrap: balance;
    }

    .skills {
        gap: 2rem;
        justify-content: space-between;
    }
    .skills img {
        width: 40px;
    }

    .contacto {
        display: none;
    }
}