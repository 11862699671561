@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url(./animations.css);
@import url(./vars.css);

:root {
    font-family: system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;

    color-scheme: dark;
    color: var(--primary-color);

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* {
    margin: 0;
    padding: 0;

    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    z-index: -11;
    background-color: rgb(var(--bg-color));
    color: rgb(var(--primary-color));
}

#root {
    min-width: 100%;
    min-height: 100vh;
    margin: 0 0;
    text-align: center;

    display: flex;
}

main {
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    position: relative;
}

footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    margin-top: 10rem;
    padding: 2rem;
    gap: 4rem;



    background-color: rgba(var(--bg-color), 0.4);

    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);

    align-items: center;
    justify-content: center;
}

.footer-nav .nav-list{
    font-size: 1rem;
    font-weight: 500;
    color: rgb(var(--primary-color));
    gap: 15px;

    align-items: center;
}
.footer-nav .nav-link {
    font-size: 1rem;
}
.footer-nav .nav-link:hover {
    color: rgba(var(--secondary-color), 0.8);
}

footer .author {
    display: flex;
    gap: 20px;

    justify-content: center;
    align-items: center;

}

footer .author img {
    width: 100px;
}

footer .author p {
    font-size: 1.3rem;
    color: rgb(var(--primary-color));
}

footer .author p strong {
    color: rgb(var(--accent-color));
}

footer .contact {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-left: 2rem;

    justify-content: center;
    align-items: start;
}

footer .contact p {
    font-size: large;
    font-weight: 600;
}

footer .contact ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 9px;

    align-items: start;
    justify-content: center;

}

footer .contact-item a {
    color: rgb(var(--tertiary-color));
    transition: color 0.2s ease-in;

    &:hover {
        color: rgba(var(--secondary-color), 0.8)
    }
}

@media (width < 665px) {
    footer .author {
        flex-direction: column;
    }
}