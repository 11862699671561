#aboutMe {
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 1rem;
    justify-content: center;
    align-items: center;
}

#aboutMe .title {
    width: 900px;
    margin-bottom: -1rem;

    font-size: 3rem;
    font-weight: 600;
    color: rgb(var(--primary-color));
    text-align: start;
}

.aboutMe-text {
    width: 900px;

    font-size: 1.12rem;
    font-weight: 500;
    color: rgb(var(--primary-color));
    text-align: start;
}

@media (width < 950px) {
    #aboutMe .title {
        width: 100%;
    }

    .aboutMe-text {
        width: 100%;
    }

}