@import url(./vars.css);

@keyframes typing {
    from {
        width: 0
    }
}

@keyframes apear-opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 100%;
    }
}

@keyframes disappear {
    from {
        opacity: 1;
        scale: 100%;
    }

    to {
        opacity: 0;
        scale: 80%;
    }
}

@keyframes apear-scale {
    from {
        transform: scale(50%, 50%);
    }

    to {
        transform: scale(100%, 100%);
    }
}

@keyframes apear-header {
    from {
        transform: translateY(-300%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes waiting {
    from {
        opacity: 0;
    }

    to {
        opacity: 0;
    }
}