.App {
  text-align: center;
  position: relative;
  margin: 2rem;

  display: flex;
  flex-direction: column;

  gap: 6rem;
  align-items: center;
}

header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 15px;
  z-index: 2;

  justify-content: center;
  align-items: center;
}