:root {
    --radius-sm: 10px;
    --radius-lm: 15px;
    --radius-xl: 20px;

    --bg-color: 31, 43, 64;
        --primary-color: 241, 240, 242;
        --secondary-color: 46, 186, 240;
        --tertiary-color: 171, 96, 56;
        --accent-color: 150, 162, 6;

}