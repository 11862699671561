#projects {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-top: 5rem;

    gap: 5rem;
    justify-content: center;
    align-items: center;
}

#projects .title {
    width: 900px;
    /* margin-bottom: -3rem; */

    box-sizing: border-box;

    font-size: 3rem;
    font-weight: 600;
    color: rgb(var(--primary-color));
    text-align: start;
}

.project-container {
    display: flex;
    width: 900px;
    flex-direction: column;
    padding: 10px;

    justify-content: center;
    align-items: center;
}

.project-img {
    width: 100%;
    border-radius: 20px;
}

.project-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    justify-content: center;
}

.project-head {
    display: flex;
    width: 100%;
    padding: 15px;
    margin: 0;

    flex-wrap: wrap;

    align-items: end;
    justify-content: space-between;
}

.project-head h1 {
    margin: 0;
    color: rgb(var(--primary-color));
    font-size: 2.4rem;
}

.project-head a {
    display: flex;
    margin: 0;

    color: rgba(var(--primary-color), 0.7);
    line-height: 50px;
    font-size: 1.3rem;
    text-decoration: none;

    text-align: center;
    transition: color 0.2s ease-in;
}

.project-head a:hover {
    color: rgb(var(--primary-color));
}

.project-resume {
    width: 100%;
    height: 40%;
    box-sizing: border-box;
    padding-inline: 15px;

    font-size: 1.12rem;
    color: rgb(var(--primary-color));
    text-align: start;
}

.project-footer {
    width: 100%;
    display: flex;
    margin-top: 10px;
    gap: 60px;
    box-sizing: border-box;

    align-items: center;
    justify-content: flex-start;
}

.project-github {
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 12px;

    border: 2px solid rgba(var(--secondary-color), 0.5);
    border-radius: 20px;

    text-wrap: nowrap;
    color: rgba(var(--primary-color), 0.9);
    text-decoration: none;
    transition: all 0.2s ease-in;
}

.project-github:hover {
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);

    border: 2px solid rgba(var(--secondary-color), 0.6);

    color: rgba(var(--primary-color), 1);

}

.project-skills {
    display: flex;
    gap: 40px;

    flex-wrap: wrap;
}

@media (width < 950px) {
    #projects .title {
        width: 100%;
        font-size: 2.6rem;
    }
    .project-container {
        width: auto;
        padding: 0;
    }

    .project-head h1 {
        font-size: 1.3rem;

    }

    .project-head a {
        font-size: 1rem;
        line-height: inherit;
    }

    .project-footer {
        justify-content: space-between;
        gap:0;
    }

    .project-skills {
        gap: 20px;
    }
    .project-skills img {
        width: 35px !important;
    }
}